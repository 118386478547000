import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'gatsby';
import Layout from '../../../components/Layout';
import Seo from '../../../components/Seo';
import WistiaPopoverVideo from '../../../components/WistiaPopoverVideo';

import './style.scss';

const Page = () => {
    return (
        <Layout navTheme="light">
            <Seo title="Vendor Onboarding - Expectations" />
            <div className="expectations-page">
                <Container className="container-1">
                    <Row className="center">
                        <Col className="centered-text header-text">
                            <h1 className="white-text max-width-900 margin-auto hero-text">
                                Welcome To Your Partner Program
                            </h1>
                        </Col>
                    </Row>
                    <Row className="center video-row">
                        <Col className="centered-text max-width-900 video">
                            <WistiaPopoverVideo id={'6bb5z6iikv'}>
                                <div className="video-wrapper">
                                    <img
                                        className="play-button"
                                        src={require('../../../images/vendor-onboarding/play-button.svg')}
                                        alt="play button"
                                    />
                                    <img
                                        className="thumbnail"
                                        src={require('../../../images/vendor-onboarding/expectations/thumbnail.png')}
                                        alt="Expectations video thumbnail"
                                    />
                                </div>
                            </WistiaPopoverVideo>
                        </Col>
                    </Row>
                    <img
                        className="white-divider"
                        src={require('../../../svgs/common/white-divider.svg')}
                        alt="white divider"
                    />
                </Container>
                <Container className="container-2">
                    <Row>
                        <Col className="introduction-col max-width-900">
                            <p>
                                As a new vendor in GlassHive, we decided to
                                create this guide to help you understand what to
                                expect out of the program along with maximizing
                                your potential results for you and your
                                partners.
                            </p>
                        </Col>
                    </Row>
                    <Row className="series-row">
                        <Col className="series-col max-width-900">
                            <p>
                                This guide is the first part in a four part
                                series that will take you through:
                            </p>
                            <ul className="series-list">
                                <li>What you should expect as a vendor</li>
                                <li>
                                    How to find the right content for the
                                    program
                                </li>
                                <li>
                                    How to select the right partners to
                                    participate in the program
                                </li>
                                <li>
                                    How to support your partner through the
                                    program to ensure success
                                </li>
                            </ul>
                        </Col>
                    </Row>
                    <Row className="expectations-row">
                        <Col className="max-width-900">
                            <h3 className="title">
                                What should your expectations be?
                            </h3>
                            <p>
                                The ultimate goal of this program is to help
                                your partners generate leads. GlassHive sets out
                                doing that in a few ways that add a tremendous
                                amount of value to you as the vendor. Creating
                                campaigns and executing marketing in an
                                efficient and scalable way is the easy part for
                                GlassHive. However, where we think this program
                                will shine is having the visibility to
                                understand the dynamics that make partners
                                successful in generating revenue.
                            </p>
                            <p>
                                When going through the program you should expect
                                to see your partners generate leads, but also
                                expect to have the <strong>visibility</strong>{' '}
                                to understand:
                            </p>
                            <ul>
                                <li>
                                    Which partners are executing proper follow
                                    up
                                </li>
                                <li>
                                    All leads generated across the program in a
                                    simple and easy way
                                </li>
                                <li>
                                    Campaign results give you a better idea of
                                    what your partner’s prospects might be
                                    interested in
                                </li>
                            </ul>
                            <p>
                                The value of <strong>visibility</strong> is
                                paramount in this program. It will provide you
                                the information you need to support your
                                partners and understand where they need help.
                            </p>
                        </Col>
                    </Row>
                    <Row className="sales-cycle-row max-width-900 expectation">
                        <Col className="one-half">
                            <img
                                className="sales-cycle"
                                src={require('../../../images/vendor-onboarding/expectations/sales-cycle-icon.png')}
                                alt="sales cycle icon"
                            />
                        </Col>
                        <Col className="one-half text-wrapper">
                            <h3 className="title">Sales cycle</h3>
                            <p>
                                Consider what your partners will be selling and
                                who they are selling to. For example, a backup
                                service being sold to a 20 employee business can
                                have a sales cycle (lead to close) as short as
                                15 days and up to 45 days depending on that
                                partner’s skills. However, if your partners are
                                selling enterprise level products with a
                                relatively high fee to a business that has many
                                employees and a larger leadership structure, you
                                can expect a sales cycle of 6 months or more.
                                You may be on a 3 month program that will
                                generate leads but you may not see partners
                                enter proposals or generate revenue for 3 months
                                after the program is complete depending on the
                                sales cycle.
                            </p>
                        </Col>
                    </Row>
                    <Row className="campaign-length-row max-width-900 expectation">
                        <Col className="one-half text-wrapper">
                            <h3 className="title">Length of the campaign</h3>
                            <p>
                                General marketing philosophy shows us that a
                                good campaign considers 3 basic phases:
                            </p>
                            <ul>
                                <li>Awareness</li>
                                <li>Consideration</li>
                                <li>Decision making</li>
                            </ul>
                            <p>
                                Whether you are on a 3 month program or a 12
                                month program these phases always apply but what
                                can change is the amount of time you are in each
                                phase based on solution type and audience. For
                                example, complex solutions geared towards
                                knowledgeable CIOs can benefit from a longer
                                consideration phase since they most likely are
                                aware of the service but need certain details to
                                be more willing to talk to a consultant about
                                the possibilities. If you have a brand new list
                                with prospects that don’t know you very well,
                                they can benefit from a longer
                                awareness/education phase that will prime them
                                to be more responsive during the consideration
                                phase of the campaign. This can affect how fast
                                or slow leads move through the sales process and
                                may warrant a longer overall campaign to achieve
                                the desired outcome.
                            </p>
                        </Col>
                        <Col className="one-half">
                            <img
                                className="campaign-length"
                                src={require('../../../images/vendor-onboarding/expectations/campaign-icon.png')}
                                alt="campaign icon"
                            />
                        </Col>
                    </Row>
                    <Row className="sales-funnel-row max-width-900 expectation">
                        <Col className="two-fifth">
                            <img
                                className="sales-funnel"
                                src={require('../../../images/vendor-onboarding/expectations/sales-funnel-icon.png')}
                                alt="sales funnel icon"
                            />
                        </Col>
                        <Col className="three-fifth text-wrapper">
                            <h3 className="title">Partner sales maturity</h3>
                            <p>
                                In this program your primary lead type will be
                                top of funnel leads. This means they are showing
                                interest through activities but are not
                                necessarily a “please call me to take my money”
                                form submission. The form submissions may come,
                                but we suggest that partners selected for this
                                program should be able to drive sales velocity
                                by proactively engaging with leads as they
                                interact with your content via emails, assets
                                and landing pages. Partners in this program will
                                have selected prospects that meet their basic
                                MQL qualifications such as: company size,
                                vertical, location, and contact type prior to
                                any campaigns being launched. When leads are
                                generated in GlassHive they will be from the
                                pre-selected prospects that are now leads
                                because they have taken actions to click, read
                                and or submit on your marketing content. A
                                partner’s willingness, sales activity
                                consistency and quality of follow-up will affect
                                both speed of revenue generation and quantity or
                                deals within a given timeframe.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="four-fifth max-width-900">
                            <p>
                                In order to keep this information simple to
                                consume and easy to remember, we have split
                                these onboarding topics into a series. You
                                should expect to receive the next topic soon but
                                if you would like to keep reading please click
                                on any of the following links below.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="vendor-onboarding-wrapper">
                            <Link
                                to="/vendor-onboarding/content-selection"
                                className="vendor-onboarding part"
                            >
                                Part 2: Program Content selection
                            </Link>
                            <Link
                                to="/vendor-onboarding/selecting-the-right-partner"
                                className="vendor-onboarding part"
                            >
                                Part 3: Proper Partner Selection
                            </Link>
                            <Link
                                to="/vendor-onboarding/ensure-ongoing-success"
                                className="vendor-onboarding part"
                            >
                                Part 4: Ensuring Ongoing Success
                            </Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="contact-us-col">
                            <p>
                                Please don’t hesitate to{' '}
                                <a
                                    href="mailto:info@glasshive.com"
                                    className="contact-us"
                                >
                                    contact us
                                </a>{' '}
                                if you have any questions.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default Page;
